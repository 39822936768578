<template>
    <div class="login">
        <van-icon name="arrow-left" class='back' size='25px' @click='back'/>
        <div class='login_cont'>
            <div class='login_title'>欢迎登录医护到家</div>
            <div class='form_cont'>
                <van-field v-model="tel" placeholder="输入手机号" class='tel_input' clearable type='number'/>
            </div>
            <div class='code_cont'>
                <van-field v-model="code" placeholder="输入验证码" class='tel_input' maxlength="6" @input='inputChange'/>
                <div class='send_code' @click='send_code'>{{isSendCodeFlag ? reSendSecond + 's' : '获取验证码'}}</div>
            </div>
            <div class='tips'>未注册手机验证后自动注册</div>
            <van-button type="success" color='#00C291' class='submit_btn' round block :disabled='!tel || !code' @click='submitLogin'>登录</van-button>
            <div class='check_cont'>
                <van-checkbox v-model="checked" icon-size='14'></van-checkbox>
                <div class='check_tips' @click='checked = !checked'>
                    我已阅读并同意<span class='href_html' @click.stop='tostatic("agreement")'>《医护到家服务协议》</span>和<span class='href_html' @click.stop='tostatic("privacy")'>《隐私政策条款》</span>
                </div>
            </div>
        </div>
        <van-dialog v-model:show="codeShow" show-cancel-button confirmButtonColor='#00C291' confirmButtonText='确认' @confirm='validation_code' @cancel='codeShow = false' :before-close='beforeClose'>
            <div class='reson'>获取验证码</div>
            <div class='reson_tips'>请输入图片中出现的人机识别码</div>
            <div class='img_cont'>
                <img :src='code_img'><van-icon name="replay" @click='send_code'/>
            </div>
            <div class='pay_input'>
                <van-field v-model="check_calculate" placeholder="请输入结果" type='number'/>
            </div>
        </van-dialog>
        <div class='tips fixb' v-if='store.state.channel != "1"'>{{channel[store.state.channel].bottm}}</div>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { Store, useStore } from 'vuex';
import { Toast,Dialog } from 'vant';
import loginApi from '@axios/login';
import homeApi from '@axios/home';
import publiObj from '@assets/js/public';
import { channel } from '@/config/channel.js';
const TIME_COUNT = 60;


export default {
  name: 'login',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let tel_reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
    let checked = ref(true);
    let codeShow= ref(false);
    let code_img = ref('');
    let code_key = ref('');
    let tel = ref('');
    let code = ref('');
    let check_calculate = ref('');
    let isSendCodeFlag = ref(false);
    let reSendSecond = ref(TIME_COUNT);
    let verifyId = '';

    if(publiObj.isWeiXin() && !store.state.openId){
        // !route.query.code
        //如果没有code 获取code
        if(!route.query.code){
            let params = {
                redirect_uri : location.origin + '/login'
            }
            loginApi.getCode(params).then((res)=>{
                 window.location.href = res.data
            }).catch((e)=>{
                if(e.data){
                    window.location.href = e.data
                }
            })
        }else{
            let params = {
                code : route.query.code
            }
            loginApi.getOpenId(params).then((res)=>{

                store.commit('setOpenId',res.data.openid);
            }).catch((e)=>{

            })
        }
    }


    const send_code = () => {

        if(!tel.value || !tel_reg.test(tel.value)){
            Toast('请输入正确手机号');
            return;
        }

        if(!checked.value){
            Toast('请您同意协议与条款');
            return
        }

        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'getVerify',
            source : 'h5_users_002'
        }))
        formData.append('encryption',false)


        if (isSendCodeFlag.value) {
          return null
        }
        check_calculate.value = ''

        loginApi.loginAction(formData).then((res)=>{
            codeShow.value = true;
            code_img.value = res.data.imgUrl;
            code_key.value = res.data.key

        })
    }

    const inputChange = (value) => {
        if(code.value.length == 6){
            submitLogin()
        }
    }

    let interval;

    const validation_code = () => {
        if(!check_calculate.value){
            Toast('请输入图片结果');
            return
        }
        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'checkVerify',
            yzmResult : check_calculate.value,
            key : code_key.value,
        }))
        formData.append('encryption',false)
        isSendCodeFlag.value = true
        loginApi.loginAction(formData).then((res)=>{
            codeShow.value = false;

            send_code_action();
        }).catch(res => {
            clearInterval(interval)
            reSendSecond.value = TIME_COUNT
            isSendCodeFlag.value = false
        })
    }

    const send_code_action = () => {


        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'authcodesend',
            yzmResult : check_calculate.value,
            key : code_key.value,
            usermobile : tel.value,
            type : '4',
            sendtype : '0',
            source : 'h5_users_002',
            sid : store.state.sid,
            version : store.state.version,
            newversion : store.state.newversion,

        }))
        formData.append('encryption',false)
        loginApi.loginAction(formData).then((res)=>{
            verifyId = res.codeid;

            interval = setInterval(() => {
                reSendSecond.value--;
                if (reSendSecond.value === 0) {
                    isSendCodeFlag.value = false
                    reSendSecond.value = TIME_COUNT
                    clearInterval(interval)
                }
            }, 1000)

        }).catch(res => {
            clearInterval(interval)
            reSendSecond.value = TIME_COUNT
            isSendCodeFlag.value = false
        })


    }

    const back = () => {
        router.back()
    }

    const beforeClose = () => {
        return false;
    }

    const submitLogin = () => {
        if(!checked.value){
            Toast('请您同意协议与条款');
            return
        }

        if(publiObj.isWeiXin()){
            loginWx();
            return;
        }

        let formData = new FormData();
        formData.append('data',JSON.stringify({
            function : 'loginNew',
            usermobile : tel.value,
            passwordType : '2',
            verifyCode : code.value,
            verifyId : verifyId,
            userType : '2',
            source : 'h5_users_002',
            version : store.state.version,
            newversion : store.state.newversion,
            sid : store.state.sid,
          //护士任务参数
          Recommenduserphone:store.state.Recommenduserphone + '',
          batchNumber:store.state.batchNumber + ''
        }))

        formData.append('encryption',false)

        loginApi.loginAction(formData).then((res)=>{
            store.commit('setToken',res.token + '');
            store.commit('setUid',res.user.userId + '');
            //此处需要改为哪里来 就到哪里去的原则
            let pathName = localStorage.getItem('url') || 'home'
            let pathq = localStorage.getItem('urlq') || '{}'

          if(store.state.Recommenduserphone){
            let pathName1 = localStorage.getItem('detailUrl');
            let pathq1 = localStorage.getItem('detailQuery') || '{}';

            Dialog.alert({
              title:'温馨提示',
              message:'恭喜您，已跟护士绑定成功',
              theme:'round-button',
              confirmButtonColor:'rgb(0, 194, 145)'
            }).then(()=> {
              router.replace({ name: pathName1 ,query:JSON.parse(pathq1)})


            })

          }
          if(route.query.fromWay == '1'){//二维码扫码进入
            if(store.state.token){
              getUserInfo();
              return;
            }
          }
          // const thirdSid = localStorage.getItem('thirdSid') //获取渠道2
          // if(thirdSid){
          //   router.push({
          //     name : 'home',
          //     query:{
          //       thirdSid:thirdSid
          //     }
          //   })
          //   return;
          // }
          router.replace({ name: pathName ,query:JSON.parse(pathq)})

        }).catch( res => {

        })
    }

    const loginWx = () => {
        let params = {
            bindPhone : tel.value,
            openId : store.state.openId,
            verifyId : verifyId,
            // verifyId : 29933801,
            passwordType : '2',
            userType : '2',
            verificationCode : code.value,
            skipCode : '',
            _from : 'h5',
            source : 'h5_users_002',
          //护士任务参数
          Recommenduserphone:store.state.Recommenduserphone + '',
          batchNumber:store.state.batchNumber + ''
        }
        console.log(params)
        let formData = new FormData();
        for(var i in params){
            formData.append(i,params[i])
        }
        formData.append('encryption',false)
        loginApi.wcpnLogin(formData).then((res)=>{
            store.commit('setToken',res.token + '');
            store.commit('setUid',res.userId + '');
            let pathName = localStorage.getItem('url') || 'home'
            let pathq = localStorage.getItem('urlq') || '{}'
          if(store.state.Recommenduserphone){
            let pathName1 = localStorage.getItem('detailUrl');
            let pathq1 = localStorage.getItem('detailQuery') || '{}';
            Dialog.alert({
              title:'温馨提示',
              message:'恭喜您，已跟护士绑定成功',
              theme:'round-button',
              confirmButtonColor:'rgb(0, 194, 145)'
            }).then(()=> {
              // router.replace({ name: pathName1 ,query:JSON.parse(pathq1)})

            })

          }
           if(route.query.fromWay == '1'){//二维码扫码进入
             if(store.state.token){
               getUserInfo();
               return;
             }
           }

          router.replace({ name: pathName ,query:JSON.parse(pathq)})


        })
    }



    //二维码扫码进入登录页后获取用户信息（处理特殊登录渠道）
    const phone = ref('')
    const userId = ref('')
    const nickName = ref('')
    const getUserInfo = () => {
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function: "getUserInfo",
        token: store.state.token,
        userid:store.state.uid,
        login_userId_base: store.state.uid,
        _validate: "1",
        _from: "h5",
        version: store.state.version,
        newversion: store.state.newversion,
      }))
      formData.append('encryption',false)

      homeApi.getList(formData).then((res) => {
        console.log(res);
        if(res.code == '0000'){
          phone.value = res.user.userMobile;
          userId.value = res.user.userId;
          nickName.value = res.user.nickName;
          const url = `http://appyhdj.yihu365.cn/common/register?userid=6961023&sid=90009000110&mobile=18910198722&type=5&activityCode=2`

          router.push({
            name:"registerApp",
            query:{
              userid:userId.value,
              sid:'90009000110',
              mobile:phone.value,
              nickName:nickName.value,
              type:'5',
              activityCode:'2'
            }
          })
        }
      })
    }



    const tostatic = (name) => {
        router.push({
            name : name
        })
    }

    return {
        channel,
        store,
        inputChange,
        tostatic,
        submitLogin,
        beforeClose,
        validation_code,
        reSendSecond,
        isSendCodeFlag,
        check_calculate,
        code,
        tel,
        code_img,
        send_code,
        back,
        router,
        checked,
        codeShow
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .fixb{
        position: fixed;
        bottom: 100px;
        width: 100%;
        text-align: center;
    }
    .img_cont{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 0;
        img{
            margin-right:20px;
        }
    }
    .reson_tips{
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
    }
    .reson{
        padding:50px 40px;
        padding-bottom: 20px;
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
    }
    .pay_input{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding:0 40px;
        font-size: 44px;
        font-family: Gotham-Bold, Gotham;
        font-weight: bold;
        color: #313131;
        padding-bottom: 30px;
        border-bottom: 1px solid #EDEDED;
        text-align: center;
        ::v-deep(input){
           text-align: center;
        }
    }
    .login_cont{
        padding:40px;
        padding-top:150px;
        .login_title{
            font-size: 50px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #313131;
            padding-left:20px;
        }
        ::v-deep(.van-cell){
            padding:0;

        }
        .form_cont{

            .tel_input{
                margin-top:80px;
                font-size: 34px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                border-bottom: 1px solid #CDCDCD;
                padding:20px 0;
                padding-left:20px;
            }
        }

    }
    .code_cont{
        .tel_input{
            margin-top:40px;
            font-size: 34px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            border-bottom: 1px solid #CDCDCD;
            padding:20px 0;
            padding-left:20px;
        }
        position: relative;
        .send_code{
            position: absolute;
            right: 0;
            top:25px;
            padding:0 20px;
            border-left:1px solid #CDCDCD;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #00C291;
            min-width: 100px;
            text-align: center;
        }
    }
    .tips{
        font-size: 24px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #7F7E7E;
        text-align: center;
        margin-top:30px;
    }
    .submit_btn{
        margin-top:95px;
    }
    .check_cont{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top:40px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        .check_tips{
            margin-left:10px;
        }
    }
    .href_html{
        color:#1083FF;
    }
    .back{
        position: absolute;
        top:50px;
        left:50px;
    }
</style>

