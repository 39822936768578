import axios from './index'

class login {

    static loginAction(params) {
        return axios.post(`NurseHomeControl.action`,params)
    }

    static getCode(params) {
        return axios.get(`authorize/getRedirectUri`,{params:params})
    }

    static getOpenId(params) {
        return axios.get(`authorize/getOpenId`,{params:params})
    }

    //获取绑定状态
    static getBindState(params) {
        return axios.get(`authorize/getNurseByOpenId`,{params:params})
    }
    //护士端绑定接口
    static nurseBinding(params) {
        return axios.post(`authorize/nurseBinding`,params)
    }

    static loginNoload(params) {
        return axios.post(`NurseHomeControl.action`,params,{headers:{
            NO_LOADING : true
        }})
    }

    static wcpnLogin(params) {
        return axios.post(`authorize/wcpnLogin`,params)
    }

    static uploadAction(params) {
        return axios.post(`upload/imgUpload.action`,params)
    }
    //活动登录提交 /ac/seveAddress
    static setFormData(params) {
        return axios.post(`ac/seveAddress`,params)
    }

}

export default login;
